/* eslint-disable react/jsx-key */
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCommands } from 'store/FileUploaderContext/selector';
import { Wrapper, FileWrapper, FileName } from './styled';
import Status from './Status';

const Body = () => {
  const commands = useSelector(selectCommands);

  return (
    <Wrapper>
      {commands.map((file) => (
        <FileWrapper>
          <FileName title={ file.name } children={ file.name } />
          <Status file={ file } />
        </FileWrapper>
      ))}
    </Wrapper>
  );
};

export default Body;
