/* eslint-disable react/jsx-key */
import React from 'react';
import { Wrapper } from '../styled';
import { CustomErrorMark, StyledLabel } from './styled';
import { useIntl } from 'react-intl';

const FinishedWithError = () => {
  const intl = useIntl();
  return (
    <Wrapper>
      <CustomErrorMark />
      <StyledLabel children={ intl.formatMessage({ id: 'uploadContext.status.finishedWithError' }) } />
    </Wrapper>
  );
};

export default FinishedWithError;
