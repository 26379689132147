/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import Wrapper from './styled';
import Body from './components/Body';
import Header from './components/Header';

const FileUploadContext = () => {
  const [showBody, setShowBody] = useState(true);

  return (
    <Wrapper>
      <Header showBody={ showBody } setShowBody={ setShowBody } />
      {showBody && <Body /> }
    </Wrapper>
  );
};

export default FileUploadContext;
