/* eslint-disable react/jsx-key */
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { hideUploadContextMenu } from '../../actions';
import {
  Wrapper, HeaderMessage, Close, ArrowExpanded, ArrowCollapsed
} from './styled';

const Header = ({ showBody, setShowBody }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const toggleBodyVisibility = () => setShowBody(!showBody);

  return (
    <Wrapper isShowingBody={ showBody }>
      <HeaderMessage children={ intl.formatMessage({ id: 'uploadContext.title' }) } />
      {showBody ? <ArrowExpanded onClick={ toggleBodyVisibility } /> : <ArrowCollapsed onClick={ toggleBodyVisibility } />}
      <Close onClick={ () => dispatch(hideUploadContextMenu()) } />
    </Wrapper>
  );
};

export default Header;
