import React, { useEffect } from 'react';
import { Col, Row } from 'Common/Components/Grid/index';
import {
  getTopHosts
} from 'Screens/Dashboard/actions/Actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { ViewAll } from 'Screens/Dashboard/components/ToolbarItems/Common/ViewAll/styled';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { DASHBOARD_FILTERS } from 'store/Filters/constants';
import { setFilter, setOrderBy } from 'store/Filters/actions';
import { redirect } from 'store/Router/actions';
import {
  Description, Empty, StyledCol, Text, Title
} from './styled';
import { ENTITIES } from 'store/Filters/entitiesConstants';

const oss = ['windows', 'cisco', 'router', 'osx', 'apple', 'linux', 'unix'];

const getHost = (host) => oss.find((os) => (host.value.os.toLowerCase().indexOf(os) > -1 ? os : ''));

const TopHosts = () => {
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const dispatch = useDispatch();
  const data = useSelector((state) => get(state, 'dashboard.tools[2].data', []));

  useEffect(() => {
    dispatch(getTopHosts());
  }, [dispatch, workspaceSelected]);

  let content = <div />;

  const onAssetSelection = (ip) => {
    dispatch(setFilter('assets', DASHBOARD_FILTERS.assetsByIp(ip)));
    dispatch(redirect(`/host/${workspaceSelected}`));
  };

  const viewAll = () => {
    const orderBy = [
      { id: 'vulnerability_critical_generic_count', desc: 'desc' },
      { id: 'vulnerability_high_generic_count', desc: 'desc' },
      { id: 'vulnerability_medium_generic_count', desc: 'desc' }];

    dispatch(setOrderBy(ENTITIES.assets.name.value, orderBy));
    dispatch(redirect(`/host/${workspaceSelected}`));
  };

  if (data && data.rows && data.rows.length > 0) {
    content = (
      <div>
        <Row centered>
          <Col width="60%" />
          <Col width="20%" textAlign="center"><Description>Vulns</Description></Col>
          <Col width="20%" />
        </Row>
        <StyledCol>
          {
              data.rows
                .sort((a, b) => b.value.vulns - a.value.vulns)
                .slice(0, 6)
                .map((host, index) => (
                  <Row centered key={ `dashboard_top_hosts_${index}` }>
                    <Col width="60%" onClick={ () => onAssetSelection(host.value.ip) }><Text bold>{ host.value.ip }</Text></Col>
                    <Col width="20%" textAlign="center"><Text>{ host.value.vulns }</Text></Col>
                    <Col width="20%" textAlign="center"><Text>{ getHost(host) }</Text></Col>
                  </Row>
                ))
            }
        </StyledCol>
        <Row><ViewAll onClick={ viewAll }>View All</ViewAll></Row>

      </div>
    );
  } else {
    content = <Empty>No most vulnerable assets has been found yet.</Empty>;
  }

  return (
    <Row>
      <Col width="100%">
        <Row>
          <Col><Title>Most Vulnerable Assets</Title></Col>
        </Row>
        {content}
      </Col>
    </Row>
  );
};

export default TopHosts;
