import styled from 'styled-components';
import command from 'Images/command.svg';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  margin-top: 10px;
  display: flex;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;

export const DateRow = styled.div`
  font-weight: 500;
`;
DateRow.displayName = 'DateRow';

export const Command = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.blueCerulean};
  font-size: 14.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.48;
  letter-spacing: 0.11px;
  text-transform: lowercase;
  text-overflow: ellipsis;
`;
Command.displayName = 'Command';

export const CommandIcon = styled.div`
  margin-right: 10px;
  min-width: 16px;
  height: 16px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 16px;
  background-image: url(${command});
`;
CommandIcon.displayName = 'CommandIcon';

export const User = styled.div`
  color: ${colors.blueGrey};
  font-size: 11.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.13;
  letter-spacing: normal;
`;
User.displayName = 'User';

export const Params = styled.div`
  color: ${colors.dark};
`;
Params.displayName = 'Params';

export const CreateDate = styled(User)``;
CreateDate.displayName = 'CreateDate';

export const Title = styled.div`
  color: ${colors.dark2};
  font-size: 17.5px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 8px;
  text-align: left;
  overflow-wrap: break-word;
`;
Title.displayName = 'Title';

export const Detail = styled.div`
  width: 233px;
  height: 395px;
  margin: 20px 19px 0 21px;
  padding: 21px 51px 23px 21px;
  border-radius: 1px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
  background-color: ${colors.white};
  width: 25%;
`;
Detail.displayName = 'Detail';

export const Tools = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;
Tools.displayName = 'Tools';

export const DetailTitle = styled.div`
  text-align: left;
  font-size: 14.5px;
  font-weight: 500;
  color: ${colors.dark2};
  margin-bottom: 17px;
`;
DetailTitle.displayName = 'DetailTitle';

export const DetailContent = styled.div`
  font-size: 11.5px;
  line-height: 1.91;
  color: ${colors.grey19};
  overflow-wrap: break-word;
`;
DetailContent.displayName = 'DetailContent';
