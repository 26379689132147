import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Wrapper, Item, Label, TotalBlack, TotalPurple, TotalGrey, WrapperItem
} from './styled';

const Stats = ({
  totalVulns, totalAssets, totalServices, totalConfirmed, totalCritical, totalOpen
}) => (
  <Wrapper>
    <WrapperItem>
      <Item>
        <TotalBlack>{ totalVulns }</TotalBlack>
        <Label>VULNERABILITIES</Label>
      </Item>
      <Item>
        <TotalGrey>{ totalAssets }</TotalGrey>
        <Label>ASSETS</Label>
      </Item>
      <Item>
        <TotalGrey>{ totalServices }</TotalGrey>
        <Label>SERVICES</Label>
      </Item>
    </WrapperItem>
    <WrapperItem>
      <Item>
        <TotalBlack>{ totalConfirmed }</TotalBlack>
        <Label>CONFIRMED</Label>
      </Item>
      <Item>
        <TotalPurple>{ totalCritical }</TotalPurple>
        <Label>CRITICAL</Label>
      </Item>
      <Item>
        <TotalGrey>{ totalOpen }</TotalGrey>
        <Label>OPEN</Label>
      </Item>
    </WrapperItem>
  </Wrapper>
);

Stats.propTypes = {
  totalVulns: PropTypes.number.isRequired,
  totalAssets: PropTypes.number.isRequired,
  totalServices: PropTypes.number.isRequired,
  totalConfirmed: PropTypes.number.isRequired,
  totalCritical: PropTypes.number.isRequired,
  totalOpen: PropTypes.number.isRequired
};

export default Stats;
