/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import SearchList from 'Common/Components/SearchList';
import WorkspaceList from 'Common/Components/WorkspaceList';
import { selectTriggerAgentWs } from 'store/Agents/selectors';
import { addTriggerAgentWs, removeTriggerAgentWs, setTriggerAgentWs } from 'store/Agents/actions';
import { useIntl } from 'react-intl';
import {
  Wrapper, Title, WrapperField, Label
} from './styled';

const WorkspaceSelector = ({ currentTool }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const addedWorkspaces = useSelector(selectTriggerAgentWs);
  const allWorkspaces = useSelector(selectActiveWorkspaces);
  const notAddedWorkspaces = allWorkspaces.filter((ws) => addedWorkspaces.every((added) => ws.id !== added.id));
  const workspaceList = notAddedWorkspaces.map((ws) => ({ primary: ws.name, secondary: `${ws.stats.total_vulns}`, id: ws._id }));

  const title = intl.formatMessage({ id: 'agents.triggerAgent.wsSelector.title' });
  const subtitle = intl.formatMessage({ id: 'agents.triggerAgent.wsSelector.subtitle' });

  const onRemoveWs = (ws) => dispatch(removeTriggerAgentWs(ws));

  const onSelectWs = (ws) => dispatch(addTriggerAgentWs(ws));

  useEffect(() => {
    dispatch(setTriggerAgentWs([]));
  }, [dispatch]);

  if (!currentTool) return null;
  return (
    <Wrapper>
      <Title children={ title } />
      <Label children={ subtitle } />
      <WrapperField>
        <SearchList onSelect={ onSelectWs } items={ workspaceList } />
        <WorkspaceList workspaces={ addedWorkspaces } onRemove={ onRemoveWs } />
      </WrapperField>
    </Wrapper>
  );
};

export default WorkspaceSelector;
