import React from 'react';
import { useIntl } from 'react-intl';
import { Wrapper, Title, FieldWrapper } from './styled';
import CheckboxField from './CheckboxField';

const AdvancedOptions = ({ ignoreInfo, setIgnoreInfo, resolveHost, setResolveHost }) => {
  const intl = useIntl();
  const ignoreInfoLabel = intl.formatMessage({ id: 'importFileCreationModal.advancedOptions.ignoreInfo' });
  const resolveHostLabel = intl.formatMessage({ id: 'importFileCreationModal.advancedOptions.resolveHost' });

  return (
    <Wrapper>
      <Title children={ intl.formatMessage({ id: 'importFileCreationModal.advancedOptions.title' }) } />
      <FieldWrapper>
        <CheckboxField defaultValue={ ignoreInfo } label={ ignoreInfoLabel } onChange={ (checked) => setIgnoreInfo(checked) } />
        <CheckboxField defaultValue={ resolveHost } label={ resolveHostLabel } onChange={ (checked) => setResolveHost(checked) } />
      </FieldWrapper>
    </Wrapper>
  );
};

export default AdvancedOptions;
