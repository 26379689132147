import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper, Input, List, DownArrow, InputContainer, Item, Primary, Secondary
} from './styled';

const SearchList = ({ items, onSelect, placeholder }) => {
  const [showList, setShowList] = useState(false);
  const [filterBy, setFilterBy] = useState('');

  const onInputChange = (e) => {
    setFilterBy(e.target.value);
    setShowList(true);
  };

  const onItemClicked = (item) => {
    onSelect(item);
    setShowList(false);
  };

  const getItems = () => (filterBy.length === 0 ? items : items.filter((item) => item.primary.toUpperCase().includes(filterBy.toUpperCase())));

  return (
    <Wrapper>
      <InputContainer onClick={ () => setShowList(!showList) }>
        <Input onChange={ onInputChange } placeholder={ placeholder } />
        <DownArrow />
      </InputContainer>
      {showList &&
        (
        <List>
          {getItems().map((item) => (
            <Item onClick={ () => onItemClicked(item) } key={ `SearchListItem_${item.primary}` }>
              <Primary>{item.primary}</Primary>
              {item.secondary && <Secondary>{item.secondary}</Secondary>}
            </Item>
          ))}
        </List>
        )}
    </Wrapper>
  );
};

SearchList.defaultProps = {
  items: [],
  placeholder: ''
};

SearchList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  })),
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

export default SearchList;
