import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as LockImage } from 'Images/ws-lock.svg';
import { ReactComponent as UnlockImage } from 'Images/ws-unlock.svg';
import { disableTextSelection } from 'Styles/effects/text';
import { pressed } from 'Styles/effects/click';
import { fadeIn, fadeOut } from 'Styles/effects';

export const Wrapper = styled.div`
  width: 375px;
  height: 465px;
  margin: 0 30px 33px 0;
  padding: 25px 30px 22px 32px;
  border-radius: 2px;
  box-shadow: 0 2px 20px 0 rgba(28, 34, 67, 0.05);
  border: solid 1px ${colors.iceBlue};
  background-color: ${colors.paleGrey};
  text-align: left;
  cursor: pointer;
  ${({ selected }) => selected && `
    border: 1px solid ${colors.blueCerulean};
  `}
  ${({ active }) => (active ? fadeIn(0.55, 1) : fadeOut(1, 0.55))}
  ${disableTextSelection}
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.dark2};
  text-align: left;
  padding-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

`;
Title.displayName = 'Title';

export const Label = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.grey19};
`;
Label.displayName = 'Label';

export const Text = styled(Label)`
  font-weight: normal;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
Text.displayName = 'Text';

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 13px;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;
FieldContainer.displayName = 'FieldContainer';

export const ReadOnlyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;
ReadOnlyContainer.displayName = 'ReadOnlyContainer';

export const ActiveContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 999;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;
ActiveContainer.displayName = 'ActiveContainer';

export const LockIcon = styled(LockImage)`
  z-index: 999;
  ${pressed}
`;
LockImage.displayName = 'LockImage';

export const UnlockIcon = styled(UnlockImage)`
  z-index: 999;
  ${pressed}
`;
UnlockIcon.displayName = 'UnlockIcon';
