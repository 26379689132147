import styled from 'styled-components';
import { ReactComponent as CheckMark } from 'Images/icon-toolbar-confirmed-on.svg';
import { Label } from '../styled';

export const StyledLabel = styled(Label)`
  color: #0082ec;
  margin-left: 10px;
`;
StyledLabel.displayName = 'StyledLabel';

export const CustomCheckMark = styled(CheckMark)`
  cursor: default;
  flex: 0 0 25px;
  height: 25px;
  `;
CustomCheckMark.displayName = 'CustomCheckMark';
