import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Wrapper, Workspace, Name, CriticalVulns, TotalVulns, TrashIcon
} from 'Common/Components/WorkspaceList/styled';
import isEmpty from 'lodash/isEmpty';

const WorkspaceList = ({ workspaces, onRemove }) => {
  const intl = useIntl();
  const totalVulnsText = intl.formatMessage({ id: 'agents.create.stepts.chooseWorkspaces.vulns' });
  const criticalVulnsText = intl.formatMessage({ id: 'agents.create.stepts.chooseWorkspaces.critical' });

  const isOdd = (workspaceNumber) => workspaceNumber % 2 !== 0;

  if (isEmpty(workspaces)) return null;
  return (
    <Wrapper>
      {workspaces.map((ws, i) => (
        <Workspace key={ `Workspace_${ws._id}` } isOdd={ isOdd(i) }>
          <Name>{ ws.name }</Name>
          <TotalVulns>{ `${ws.stats.total_vulns} ${totalVulnsText}` }</TotalVulns>
          <CriticalVulns>{ `${ws.stats.critical_vulns} ${criticalVulnsText}` }</CriticalVulns>
          <TrashIcon onClick={ () => onRemove(ws) } />
        </Workspace>
      ))}
    </Wrapper>
  );
};

WorkspaceList.propTypes = {
  workspaces: PropTypes.arrayOf(PropTypes.shape({
    stats: PropTypes.shape({
      total_vulns: PropTypes.number.isRequired,
      critical_vulns: PropTypes.number.isRequired
    }),
    name: PropTypes.string,
    _id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  })).isRequired,
  onRemove: PropTypes.func.isRequired
};

export default WorkspaceList;
