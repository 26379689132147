import styled from 'styled-components';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const SummaryContainer = styled.div`
  width: 100%;
  height: 229px;
  border-radius: 3px;
  border: solid 1px #f6f6f8;
  background-color: #f6f6f8;
  overflow-y: auto;
`;
SummaryContainer.displayName = 'SummaryContainer';

export const Icon = styled.div`
  text-align: center;
  margin-top: 34px;
`;
Icon.displayName = 'Icon';

export const Filenames = styled.div`  
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;  
  letter-spacing: normal;
  text-align: left;
  color: #419bf9;
  text-align: center;
  margin-top: 7px;

  span {
    margin-right: 5px;
  }
`;
Filenames.displayName = 'Filenames';

export const SubText = styled.div`  
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #7a7e8f;
  text-align: center;
  margin-top: 34px;
  padding: 0 20px;
  padding-bottom: 34px;

`;
SubText.displayName = 'SubText';

export const File = styled.img`
  width: 28px;
  height: 34px;
  color: #7a7e8f;  
`;
File.displayName = 'File';

export const ErrorText = styled.div`  
  color: #c71717;
`;
ErrorText.displayName = 'ErrorText';
