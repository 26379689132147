/* eslint-disable react/jsx-key */
import React from 'react';
import { Wrapper } from '../styled';
import { CustomCheckMark, StyledLabel } from './styled';
import { useIntl } from 'react-intl';

const FinishedOk = () => {
  const intl = useIntl();
  return (
    <Wrapper>
      <CustomCheckMark />
      <StyledLabel children={ intl.formatMessage({ id: 'uploadContext.status.finishedOk' }) } />
    </Wrapper>
  );
};

export default FinishedOk;
