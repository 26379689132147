/* eslint-disable react/jsx-key */
import React from 'react';
import get from 'lodash/get';
import Processing from './components/Processing';
import FinishedOk from './components/FinishedOk';
import FinishedWithError from './components/FinishedWithError';

const Status = ({ file }) => {
  const finished = get(file, 'finished', false);
  const error = get(file, 'error', true);

  if (!finished) return <Processing />;
  if (error) return <FinishedWithError />;
  return <FinishedOk />;
};

export default Status;
