import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #eff3f7;
  max-height: 182px;
  overflow-y: auto;
  & > *:not(:last-child) {
    border-bottom: 1px solid #eff3f7;
  }
`;
Wrapper.displayName = 'Wrapper';

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 45px;
  align-items: center;
  width: 100%;
  padding: 10px 25px;
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;
FileWrapper.displayName = 'FileWrapper';

export const FileName = styled.div`
  flex: 1 1 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${colors.dark2};
  font-size: 14px;
`;
FileName.displayName = 'FileName';
