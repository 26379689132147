import styled from 'styled-components';

export const Wrapper = styled.div`

`;
Wrapper.displayName = 'Wrapper';

export const AditionalText = styled.div`
    width: 220px;
    height: 36px;    
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.67;
    letter-spacing: 0.1px;
    color: #afb1bc;
    margin-left: 20px;
`;
AditionalText.displayName = 'AditionalText';

export const ListWraper = styled.div`
margin-top: 17px;
`;
ListWraper.displayName = 'ListWraper';

export const Row = styled.div`
  display:flex;
`;
export const Data = styled.div`
margin-left: 14px;
`;
Data.displayName = 'Data';
